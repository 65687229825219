import { CollectionType } from "@ehabitation/ts-utils/browser";
import { Spinner } from "@ehabitation/ui";
import { MonochromeButton } from "@ehabitation/ui/src/MonochromeButton";
import { asyncDownloadReference, downloadReference } from "Components/RiskAnalysisExports/helpers";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { db } from "firebaseConfig";
import { ExportTypes, logExportEvent } from "helpers/analytics";
import { useEffect, useState } from "react";
import { BiDownload } from "react-icons/bi";

export const DownloadAcumenPostMitigationCalendar = (props: {
  planId: string;
  siteId: string;
  isExporting: boolean
}) => {
  const [acumenFilenamePost, setAcumentFilenamePost] = useState<string>();
  const [onLoad, setOnLoad] = useState(false);

  const downloadFile = async () => {
    if (!acumenFilenamePost) return;
    setOnLoad(true);

    const storage = getStorage();
    const storageRef = ref(storage, acumenFilenamePost);
    logExportEvent({
      planId: props.planId,
      siteId: props.siteId,
      exportType: ExportTypes.AcumenRiskCalendar,
    });
    await asyncDownloadReference(
      storageRef,
      `DeltekAcumenPreMitigationCalendar_${props.planId}`,
      "xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    setOnLoad(false);
  };

  useEffect(() => {
    const planQuery = doc(collection(db, CollectionType.Plans), props.planId);

    const unsubscribe = onSnapshot(planQuery, (snap) => {
      if (!snap.exists()) return;
      const data = snap.data();
      if (!data.generatedFiles) return;
      if (data.generatedFiles.acumenRiskXlsxPostMitigation)
        setAcumentFilenamePost(data.generatedFiles.acumenRiskXlsxPostMitigation);
    });
    return unsubscribe;
  }, [props.planId]);

  return (
    <MonochromeButton
      level="secondary"
      disabled={!acumenFilenamePost || props.isExporting || onLoad}
      onClick={downloadFile}
      className="ml-auto mr-4"
    >
      <BiDownload className="mr-4 " />
      Post-mitigation calendar
      {onLoad && <Spinner size="xSmall" />}
    </MonochromeButton>
  );
};
