import { FC, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";

import { IPlan, ISite } from "@ehabitation/ts-utils/browser";
import { Tooltip } from "@material-ui/core";
import DiscardAlert from "Components/Plan/PlanControls/DiscardAlert";
import { PlanSelector } from "Components/Plan/PlanControls/PlanSelector";
import { setAsMainPlan } from "Components/Plan/PlanControls/thunks";

import { discardPlan } from "helpers/firebase/plans";
import { BiHelpCircle, BiPrinter, BiDownload } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { useAppDispatch } from "store";

export const pageTitles = {
  "import-plan": "Import a plan",
  "settings/thresholds": "Set thresholds",
  simulate: "Run simulation",
  plan: "Plan overview",
  "tra-qsra": "Weather days breakdown (TRA)",
  "risk-drivers": "Identify risks",
  mitigations: "Mitigate risks",
  "weather-calendars-qsra": "Weather calendars (QSRA)",
  variance: "Manage change",
  weather: "Forecast weather windows",
  "settings/compensation-events": "Compensation Events",
};

export const MainWrapper: FC<{
  site: ISite;
  selectedPlan?: IPlan;
  selectableBasePlans?: IPlan[];
  setSelectedPlanId: (id: string) => void;
  printableLink?: string;
  helpLink?: string;
  selectedRiskMatrixType: string;
  setSelectedRiskMatrixType: (rmType: string) => void;
  simulationAlert?: string;
}> = ({
  site,
  selectedPlan,
  selectableBasePlans,
  setSelectedPlanId,
  printableLink,
  helpLink,
  simulationAlert,
}) => {
  const dispatch = useAppDispatch();

  const [discardPlanId, setDiscardPlanId] = useState("");

  const params = useParams() as { "*": keyof typeof pageTitles };

  const { "*": currentPage } = params;

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const handleDownloadPDF = async () => {
    if (printRef.current) {
      try {
        const contentElement = printRef.current as HTMLElement;

        // Add site name header
        const siteNameHeader = document.createElement('div');
        siteNameHeader.className = 'text-3xl font-semibold text-gray-800 mb-8 mt-8 border-b border-gray-300 pb-4 px-8 download-only';
        siteNameHeader.innerText = site.name;
        contentElement.insertBefore(siteNameHeader, contentElement.firstChild);

        // Save original scroll position and style
        const originalScroll = contentElement.scrollTop;
        const originalStyle = contentElement.style.cssText;

        // Temporarily modify element for capture
        contentElement.style.overflow = 'visible';
        contentElement.style.height = 'auto';

        // Capture image with improved settings
        const dataUrl = await toPng(contentElement, {
          quality: 1.0, // Maximum quality
          pixelRatio: 2, // Increase pixel ratio for better resolution
          backgroundColor: "white",
          height: contentElement.scrollHeight,
          width: contentElement.clientWidth,
          style: {
            transform: 'scale(1)', // Ensure no scaling is applied during capture
            transformOrigin: 'top left'
          },
          filter: (node: HTMLElement) => {
            // Optionally filter out any elements that might cause quality issues
            return !node.classList?.contains('no-export');
          }
        });

        // Restore original state
        contentElement.style.cssText = originalStyle;
        contentElement.scrollTop = originalScroll;
        contentElement.removeChild(siteNameHeader);

        // Create PDF with higher quality settings
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [1200, 850],
          compress: false // Disable compression for better quality
        });

        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        // Add image to PDF with higher quality settings
        let heightLeft = pdfHeight;
        let position = 0;
        const pageHeight = pdf.internal.pageSize.getHeight();

        // Use better quality settings when adding image
        pdf.addImage(dataUrl, "PNG", 0, position, pdfWidth, pdfHeight, undefined, 'FAST', 0);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position -= pageHeight;
          pdf.addPage();
          pdf.addImage(dataUrl, "PNG", 0, position, pdfWidth, pdfHeight, undefined, 'FAST', 0);
          heightLeft -= pageHeight;
        }

        // Save the PDF
        const now = new Date();
        const date = now.toISOString().split("T")[0];
        const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");

        const sanitizedSiteName = site.name
          .replace(/[^a-zA-Z0-9]/g, "-")
          .replace(/-+/g, "-");
        const fileName = `${sanitizedSiteName}-plan-report-${date}-${time}.pdf`;

        pdf.save(fileName);
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        setIsGeneratingPDF(false);
      }
    } else {
      setIsGeneratingPDF(false);
    }
  };

  return (
    <div className="overflow-y-auto w-full mb-2 sm:pl-10 md:pl-0">
      {isGeneratingPDF && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <p className="text-lg">Generating PDF...</p>
          </div>
        </div>
      )}
      <div
        className={`w-full min-w-0 h-full px-3 sm:px-4 md:px-6 py-4 sm:py-6 md:py-8 flex flex-col gap-2 sm:gap-4 md:gap-6`}
      >
        <div className="w-full flex justify-between items-start">
          <div className="flex gap-3 items-baseline">
            <h2 className="text-3xl">{pageTitles[currentPage]}</h2>
            {currentPage === "weather" && (
              <Tooltip
                arrow
                title={
                  <span className="text-xl">
                    <h3 className="pb-4">Colour Legend</h3>
                    <div className="flex align-middle gap-2 items-baseline pb-2">
                      <div className="w-4 h-4 bg-rose-300"></div>
                      <div>At least one threshold is broken</div>
                    </div>
                    <div className="flex align-top gap-2 items-baseline pb-2">
                      <div className="w-4 h-4 bg-amber-300"></div>
                      <div>
                        Forecasted wind is within 5 m/sec of the threshold.
                      </div>
                    </div>
                    <div className="flex align-top gap-2 items-baseline pb-2">
                      <div className="w-4 h-4"></div>
                      <div>
                        Temp (min and max) is within 3 degrees from forecasted
                      </div>
                    </div>
                    <div className="flex align-top gap-2 items-baseline pb-2">
                      <div className="w-4 h-4"></div>
                      <div>Precipitation is within 1 mm of threshold</div>
                    </div>
                    <div className="flex align-middle gap-2 items-baseline">
                      <div className="w-4 h-4 bg-green-300"></div>
                      <div>
                        Good, but only for activities that have weather risk
                      </div>
                    </div>
                  </span>
                }
              >
                <span className="flex justify-between">
                  <BsInfoCircle className="mt-1" />
                </span>
              </Tooltip>
            )}
            {currentPage === "mitigations" && (
              <Tooltip
                arrow
                title={
                  <span className="text-xl">
                    Assess weather impact on your project and evaluate risk
                    reduction strategies. Visualise the potential impacts on
                    your completion date and subsequent tasks.
                  </span>
                }
              >
                <span className="flex justify-between">
                  <BsInfoCircle className="mt-1" />
                </span>
              </Tooltip>
            )}
            {currentPage === "settings/compensation-events" && (
              <p className="italic">1 to 10 Thresholds</p>
            )}
          </div>
          <div className="flex">
            {simulationAlert && (
              <Tooltip
                arrow
                placement="bottom-start"
                title={<span className="text-xl">{simulationAlert}</span>}
              >
                <span className="flex items-center mr-4 text-amber-600 cursor-help">
                  <BsInfoCircle className="mr-2" />
                </span>
              </Tooltip>
            )}
            {helpLink && (
              <NavLink to={helpLink} target="_blank">
                <div className="flex gap-2 items-center py-[0.4rem]">
                  <span>
                    <BiHelpCircle />
                  </span>
                  <span>Help</span>
                </div>
              </NavLink>
            )}
            {!currentPage?.includes("settings") && (
              <>
                <div
                  onClick={() => {
                    setIsGeneratingPDF(true);
                    setTimeout(() => {
                      handleDownloadPDF();
                    }, 0);
                  }}
                  className="flex gap-2 items-center py-[0.4rem] px-4 cursor-pointer hover:text-gray-600"
                >
                  <span>
                    <BiDownload />
                  </span>
                  <span>Download</span>
                </div>
                <div
                  onClick={handlePrint}
                  className="flex gap-2 items-center py-[0.4rem] px-4 cursor-pointer hover:text-gray-600"
                >
                  <span>
                    <BiPrinter />
                  </span>
                  <span>Print</span>
                </div>
              </>
            )}
            {!currentPage?.includes("settings") ? (
              <>
                <div className="hidden sm:inline-block">
                  <PlanSelector
                    isShort={true}
                    plans={selectableBasePlans}
                    currentSite={site}
                    handleSelectPlan={setSelectedPlanId}
                    selectedPlan={selectedPlan?.id}
                    setMainPlan={async (site: ISite, planId: string) => {
                      await dispatch(setAsMainPlan(site, planId));
                    }}
                    setDiscardPlanModal={(planId) => {
                      setDiscardPlanId(planId);
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div ref={printRef} className="flex-1 overflow-y-auto">
          <Outlet />
        </div>
      </div>
      {discardPlanId && (
        <DiscardAlert
          message="Are you sure you want to delete the plan?"
          handleCancel={() => setDiscardPlanId("")}
          handleDiscard={async () => {
            await discardPlan(discardPlanId);
            setDiscardPlanId("");
          }}
        ></DiscardAlert>
      )}
    </div>
  );
};
