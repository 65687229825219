import { CollectionType } from "@ehabitation/ts-utils/browser";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { getBlob, getStorage, ref, StorageReference } from "firebase/storage";
import { db } from "firebaseConfig";
import { firebaseFunction } from "helpers";
import { ExportTypes, logExportEvent } from "helpers/analytics";
import fileDownload from "js-file-download";

const waitForP6ToBeGenerated = async (
  planId: string
): Promise<StorageReference> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      doc(db, CollectionType.Plans, planId),
      (snapshot) => {
        const data = snapshot.data();
        if (data?.generatedFiles?.p6RiskXer !== "pending") {
          const storage = getStorage();
          const storageRef = ref(storage, data?.generatedFiles?.p6RiskXer);
          unsubscribe();
          resolve(storageRef);
        }
      }
    );
  });
};

export const handleGetFile = async (
  siteId: string,
  planId: string,
  format: string,
  fileName: string,
  existingRiskRegister?: string | null,
  p6AssociatedCalendars?: boolean
) => {
  const storage = getStorage();
  var storageRef: StorageReference | null;
  let fileType = "xlsx";
  let fileMime =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const runGenerateAdjustedCalendars = firebaseFunction<{
    praRiskXlsx: string;
  }>("RunGenerateAdjustedCalendar");

  switch (format) {
    case "acumen":
      logExportEvent({
        siteId: siteId,
        exportType: ExportTypes.AcumenRiskCalendar,
      });
      const generateAcumenRiskPrePostCalendar = firebaseFunction(
        "GenerateAcumenRiskPrePostCalendar"
      );
      await generateAcumenRiskPrePostCalendar({
        site: siteId,
        plan: planId,
        existing_risk_register_file: existingRiskRegister,
      });
      storageRef = null;
      break;
    case "pra":
      logExportEvent({
        siteId: siteId,
        exportType: ExportTypes.PRARiskCalendar,
      });
      const generatePRARiskCalendars = firebaseFunction<{
        praRiskXlsx: string;
      }>("GeneratePRARiskCalendar");
      const {
        data: { praRiskXlsx: praFilePath },
      } = await generatePRARiskCalendars({
        site: siteId,
        plan: planId,
      });
      storageRef = ref(storage, praFilePath);
      break;
    case "oraclePrimavera":
      console.log("Hitting right function", planId, siteId);
      logExportEvent({
        siteId: siteId,
        exportType: ExportTypes.OraclePrimaveraCalendar,
      });
      const generateOpcRiskCalendars = firebaseFunction<{}>(
        "GenerateOpcRiskCalendar"
      );
      const {
        data: {},
      } = await generateOpcRiskCalendars({
        site: siteId,
        plan: planId,
      });
      storageRef = null;
      break;
    case "adjustedCalendars":
      fileType = "xer";
      fileMime = "application/xml";
      logExportEvent({
        siteId: siteId,
        exportType: ExportTypes.AdjustmentCalendars,
      });
      await updateDoc(doc(db, CollectionType.Plans, planId), {
        ["generatedFiles.p6RiskXml"]: "pending",
        ["generatedFiles.p6RiskXer"]: "pending",
      });
      // Raise workflow
      await runGenerateAdjustedCalendars({
        site: siteId,
        plan: planId,
        associated: p6AssociatedCalendars,
      });
      storageRef = await waitForP6ToBeGenerated(planId);
      break;
    case "nodesLinks":
      fileType = "xer";
      fileMime = "application/xml";
      logExportEvent({
        siteId: siteId,
        exportType: ExportTypes.AdjustmentCalendars,
      });
      await updateDoc(doc(db, CollectionType.Plans, planId), {
        ["generatedFiles.p6RiskXml"]: "pending",
        ["generatedFiles.p6RiskXer"]: "pending",
      });
      // Raise workflow
      await runGenerateAdjustedCalendars({
        site: siteId,
        plan: planId,
        associated: "associated", // Hard coding to not rely on the frontend
      });
      storageRef = await waitForP6ToBeGenerated(planId);
      break;
    case "asta":
      fileType = "xer";
      fileMime = "application/xml";
      logExportEvent({
        siteId: siteId,
        exportType: ExportTypes.AdjustmentCalendars,
      });
      await updateDoc(doc(db, CollectionType.Plans, planId), {
        ["generatedFiles.p6RiskXml"]: "pending",
        ["generatedFiles.p6RiskXer"]: "pending",
      });
      // Raise workflow
      await runGenerateAdjustedCalendars({
        site: siteId,
        plan: planId,
        associated: p6AssociatedCalendars,
      });
      storageRef = await waitForP6ToBeGenerated(planId);
      break;
    default:
      storageRef = null;
  }
  if (storageRef) {
    downloadReference(storageRef, fileName, fileType, fileMime);
  }
};

export const downloadReference = async (
  storageRef: StorageReference,
  fileName: string,
  fileType: string,
  fileMime: string
) => {
  getBlob(storageRef)
    .then((blob) => {
      fileDownload(blob, `${fileName}.${fileType}`, fileMime);
    })
    .catch((error) => {
      console.error(error.code, `could not download ${storageRef}`);
    });
};

export const asyncDownloadReference = async (
  storageRef: StorageReference,
  fileName: string,
  fileType: string,
  fileMime: string
) => {
  const blob = await getBlob(storageRef)
  try {
    fileDownload(blob, `${fileName}.${fileType}`, fileMime);
  } catch (error: any) {
    console.error(error.code, `could not download ${storageRef}`);
  }
};
